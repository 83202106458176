<template>
    <div>
        <b-row class="m-0" style="position: relative;"> 
            <b-card :class="$store.state.appConfig.layout.skin === 'dark' ?'dark-holder m-0 border-bottom rounded-0 w-100':'m-0 border-bottom rounded-0 w-100'" v-if="student && authUser().user.type != 2">
              <div class="d-flex align-items-center">
                <b-avatar 
                  v-if="student.avatar" 
                  :src="student.avatar"  
                  size="50"
                  style=" background: linear-gradient(0deg, rgba(105,34,149,1) 0%, rgba(232,65,133,1) 100%);"
                />
                <div class="ml-2">
                  <h5 class="m-0" >{{ `${student.fname} ${student.lname}` }}</h5>
                </div>
                <small class="ml-2">
                  Expand
                  <feather-icon
                    :tooltip="'none'"
                    icon="ChevronDownIcon"
                    size="16"
                  />
                </small>
                
              </div>
            </b-card>
            <b-tabs :class="$store.state.appConfig.layout.skin == 'dark' ? 'w-100 rounded-0 cutom-tabs-dark' : 'w-100 rounded-0'" content-class="mt-2 w-100 px-2" lazy>
              <b-tab title="Curriculum" active>
                <p v-if="student && authUser().user.type == 2">Here is an overview of how you are doing in the different curriculums, by selecting a curriculum you can have deeper insights into the sub-topics associated with the curriculum.</p>
                <Curriculum />
              </b-tab>
              <b-tab title="Core competencies">
                  
              </b-tab>
            </b-tabs>
        </b-row>
    </div>
</template>
  
  <script>
  import {
    BCard, 
    BRow, 
    BCol, 
    BCardText, 
    BButton,
    BLink, 
    BListGroup, 
    BListGroupItem, 
    BCardTitle, 
    BAvatar,
    BCardBody,
    BTab, BTabs,
    BAlert
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import VueApexCharts from 'vue-apexcharts'
  import apexChatData from '@/views/charts-and-maps/charts/apex-chart/apexChartData.js'
  import { mapGetters } from "vuex";
  import Curriculum from '@/views/modules/students/Curriculum.vue'

  export default {
    components: {
      BCard, 
      BRow, 
      BCol, 
      BCardText, 
      BButton,
      BLink, 
      BListGroup, 
      BListGroupItem, 
      BCardTitle, 
      BCardBody,
      BAvatar,
      BTab, BTabs,
      BAlert,
      VueApexCharts,
      Curriculum
    },
    directives: {
      Ripple,
    },
    computed: {
        ...mapGetters({
            student: "users/user",
        }),
        id(){
            return this.$route.params.id ? this.$route.params.id : null
        },
    },
    data() {
      return {
        apexChatData,
        items: [
          {title: 'School 1', students: '50 Student'},
          {title: 'School 2', students: '80 Student'},
          {title: 'School 3', students: '30 Student'},
          {title: 'School 4', students: '20 Student'},
          {title: 'School 5', students: '35 Student'},
        ]
      }
    },
    methods:{
        init(){
            this.$store.dispatch('users/showUser', this.id ? this.id : this.authUser().user.id).then(_=>{
            })
        },
    },
    mounted(){
        this.init()
    }
  }
  </script>
  
  <style lang="scss">
    @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
    @import '@core/scss/vue/libs/chart-apex.scss';
    .nav-tabs .nav-link:after {
      background: linear-gradient(90deg, rgba(232,65,133,1) 0%, rgba(232,65,133,0.6587009803921569) 100%) !important;
    }
    .nav-tabs .nav-link.active {
      color: #E84185;
    }
    .btn-primary:focus {
      background-color: #E84185 !important;
    }
    .btn-primary {
      border-color: #E84185 !important;
      background-color: #E84185 !important;
    }
    .alert-danger{
      background-color: #CF4F4F !important;
      color: #fff !important;
      border-radius: 10px !important;
    }
    [dir] .nav-tabs {
      margin-bottom: 1rem;
      background-color: #fff;
      // box-shadow: 5px 10px #8888883d;
      // box-shadow: 10px #ccc;
      // box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
      padding-top: 10px;
    }
    [dir] .nav {
     border-radius: 0rem; 
    }
    [dir] .card-body {
        padding: 1rem 1.5rem;
    }
    .cutom-tabs-dark {
      .nav-tabs{
        background-color: rgb(11, 11, 11) !important;
      }
    }

    [dir] .dark-layout .border, [dir] .dark-layout .border-top, [dir] .dark-layout .border-right, [dir] .dark-layout .border-bottom, [dir] .dark-layout .border-left{
      border: 2px solid #353535 !important;
      border-radius: 10px !important;
    }
  </style>
  